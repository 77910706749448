import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery } from "gatsby"
import Img from "gatsby-image"


import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import Footer from "../../components/footer/Footer"

import Sidebar from "../../components/major-pieces/sidebar/Sidebar"
import Subheader from "../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../components/major-pieces/subheader/SubMenu"
import PageBlock from "../../components/major-pieces/pageblock/PageBlock"
import ERIPageBlocks from "../../components/major-pieces/pageblock/ERIPageBlocks"
import getPageBlockFromData from "../../components/major-pieces/pageblock/PageBlock"

import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/menu.css"
import "../../styles/about-orthodoxy.css"


function ERI () {
	
	
	const query = graphql`
	      query {
	        allFile(
	          sort: { fields: name, order: ASC }
	          filter: { relativePath: { glob: "images/pages/about-orthodoxy/*.*" } }
	        ) {
	          edges {
	            node {
	              id
	              name
	              childImageSharp {
	                fluid {
	                  ...GatsbyImageSharpFluid
	                }
	              }
	            }
	          }
	        },
	        site {
	        	siteMetadata{
				    aboutOrthodoxyMetadata {
				      links {
				        link
				        url
				      }
				      sidebarTextblocks {
				        body
				        header
				      }
				      subheader {
				        main
				        sub
				      }
				    }
				}
			  }
			}
	`


	let data = useStaticQuery(query)

	console.log(data)

	let images = data.allFile
	let sidebarData = data.site.siteMetadata.aboutOrthodoxyMetadata.sidebarTextblocks;
	let links = data.site.siteMetadata.aboutOrthodoxyMetadata.links;
	let subheaderData = data.site.siteMetadata.aboutOrthodoxyMetadata.subheader; 

	
	const icon = images.edges.find(element => element.node.name === 'icon_text').node
	const location = ""
	
	//“Love is the essence of the holiness of the Church.”
	//― Alexander Schmemann

	return(
		<div className="site body">
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>
			<main className="site-content about-orthodoxy">
				<div>
					<Subheader data={subheaderData} /> 
				</div>
				<Img className="banner" fluid={icon.childImageSharp.fluid}/>
				<p>
				The Orthodox Church is the original Christian Church, the Church founded by the Lord Jesus Christ and described in the pages of the New Testament. Its history can be traced in unbroken continuity all the way back to Christ and His Twelve Apostles. Incredible as it seems, for over twenty centuries the Orthodox Church has continued in an undiminished and unaltered faith and practice. Today its apostolic doctrine, worship, and structure remain intact. The Orthodoxy Church maintains that the Church is the living Body of Jesus Christ.
				</p>
				<div className="external-links"> 
					<h2>More Information</h2>
					<ul>
						<li className="link-wrapper"><a href="https://www.antiochian.org/" target="_blank">Antiochian Archdiocese Of North America</a></li>
						<li className="link-wrapper"><a href="https://www.oca.org/" target="_blank">Orthodox Church in America</a></li>
						<li className="link-wrapper"><a href="https://www.goarch.org/" target="_blank">Greek Orthodox Archdiocese of America</a></li>
						<li className="link-wrapper"><a href="https://www.ancientfaith.com/" target="_blank">Ancient Faith Ministries (resource for podcasts and blogs)</a></li>
						<li className="link-wrapper"><a href="https://publicorthodoxy.org/" target="_blank">Public Orthodoxy (forum for discussion of current issues)</a></li>
					</ul>
				</div>
				<div className="suggested-reading"> 
					<h2>Suggested Reading</h2>
					<ul>
						<li className="link-wrapper"><a href="https://www.amazon.com/Orthodox-Church-New-Timothy-Ware/dp/0140146563/" target="_blank">The Orthodox Church - Metropolitan Kallistos (Ware)</a></li>
						<li className="link-wrapper"><a href="https://www.amazon.com/Orthodox-Way-Kallistos-Ware/dp/0913836583" target="_blank">The Orthodox Way - Metropolitan Kallistos (Ware)</a></li>						
						<li className="link-wrapper"><a href="https://www.amazon.com/Life-World-Sacraments-Orthodoxy/dp/0913836087" target="_blank">For the Life of the World - Fr. Alexander Schmemann</a></li>
					</ul>
				</div>
				<div className="suggested-reading"> 
					<h2>Podcasts</h2>
					<ul>
						<li className="link-wrapper">
							<a href="https://www.ancientfaith.com/podcasts/hopko" target="_blank">
								Speaking the Truth in Love - Fr. Thomas Hopko
							</a>
							</li>
						<li className="link-wrapper">
							<a href="https://eagleriverinstitute.org" target="_blank">
								Eagle River Institute
							</a>
						</li>
					</ul>
				</div>
			</main>
			<Footer />		
		</div>
		)
}

export default ERI 